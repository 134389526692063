export const LANE_TO_DESCRIPTION = {
  ALUAR: 'Aluar',
  ATLANT: 'Market Atlantic bulk',
  FREEPORT: 'Freeport Phil',
  DD: 'Drydock',
  MKT: 'Market',
  PINNACLE: 'Pinnacle',
  SIBELCO: 'Sibelco',
  SOJITZ: 'Sojitz',
  STARTUP: 'Startup',
  TIZIR: 'Tizir',
  'MKT-UNALL-ATL': 'Market unallocated Atlantic',
} as const;

export const LANE_TO_CONTINENT = {
  ATL: 'Atlantic',
  AU: 'Australia',
  'AU(B)': 'Australia (B)',
  FE: 'Far East',
  EU: 'Europe',
  ENA: 'East North America',
  ESA: 'East South America',
  'ESA(B)': 'East South America (B)',
  WSA: 'West South America',
  'WSA(B)': 'West South America (B)',
  WNA: 'West North America',
  'WNA(B)': 'West North America (B)',
  MED: 'Mediterranean',
  ME: 'Middle East',
  NZ: 'New Zealand',
  SL: 'ST. Lawrence',
  SUZ: 'Suzano (Brazil)',
  SAF: 'South Africa',
  'SAF(B)': 'South Africa (B)',
  IND: 'India',
  PAC: 'Pacific',
  MKT: 'Market',
  SEA: 'South East Asia',
} as const;
