import { HTTP } from './HTTP.js';

export class ShipmentsService {
  /**
   * @param {string} organisationId
   * @param {string | undefined} shipmentId
   */

  constructor(organisationId, shipmentId) {
    this.organisationId = organisationId;
    this.shipmentId = shipmentId;

    this.baseUrl = `/api/customer/organisation/${organisationId}/shipments`;
    this.baseUrlV2 = `/api/customer/organisation/${organisationId}/shipments-v2`;

    this.baseOrgUrl = `/api/customer/organisation/${organisationId}`;
  }

  indexV2() {
    return HTTP.make(this.baseUrlV2, 'get');
  }

  notifications(shipments) {
    return HTTP.make(`${this.baseUrlV2}/notifications`, 'put').data(shipments);
  }

  showV2(loadPortId, dischargePortId) {
    const path = `${this.baseUrlV2}/${this.shipmentId}/lp/${loadPortId}/dp/${dischargePortId}`;
    return HTTP.make(path, 'get');
  }

  tracking(loadPortId, dischargePortId) {
    const path = `${this.baseUrlV2}/${this.shipmentId}/lp/${loadPortId}/dp/${dischargePortId}/tracking`;
    return HTTP.make(path, 'get');
  }

  preview(loadPortId, dischargePortId) {
    const path = `${this.baseUrlV2}/${this.shipmentId}/lp/${loadPortId}/dp/${dischargePortId}/preview`;
    return HTTP.make(path, 'get');
  }

  milestones(loadPortId, dischargePortId) {
    const path = `${this.baseUrlV2}/${this.shipmentId}/lp/${loadPortId}/dp/${dischargePortId}/milestones`;
    return HTTP.make(path, 'get');
  }

  byVoyageId(voyageId) {
    const path = `${this.baseOrgUrl}/voyages/${voyageId}/first_shipment`;
    return HTTP.make(path, 'get');
  }

  voyage() {
    return {
      tracking: () =>
        HTTP.make(`${this.baseUrl}/${this.shipmentId}/tracking_details`, 'get'),
    };
  }

  documents(loadPortId, dischargePortId) {
    const path = `${this.baseUrlV2}/${this.shipmentId}/lp/${loadPortId}/dp/${dischargePortId}/bol`;
    return {
      index: () => HTTP.make(path, 'get'),
      download: (billOfLadingNo) =>
        HTTP.make(`${path}/${billOfLadingNo}`, 'get'),
    };
  }

  contacts(loadPortId, dischargePortId) {
    const path = `${this.baseUrlV2}/${this.shipmentId}/lp/${loadPortId}/dp/${dischargePortId}/contacts`;
    return HTTP.make(path, 'get');
  }

  carbonOffsetting() {
    const path = `${this.baseUrlV2}/carbon`;

    return {
      index: () => {
        return HTTP.make(`${path}/shipments`, 'get');
      },
      orders: () => {
        return HTTP.make(`${path}/orders`, 'get');
      },
      chooose: (shipments) => {
        return HTTP.make(path, 'post').data(
          shipments.map((shipment) => ({
            loading_port_id: shipment.loadingPort.id,
            discharge_port_id: shipment.dischargePort.id,
            cargo_ref: shipment.bookingNo,
          })),
        );
      },
    };
  }

  share(loadPortId, dischargePortId) {
    const path = `${this.baseUrlV2}/${this.shipmentId}/lp/${loadPortId}/dp/${dischargePortId}/share`;
    return {
      store: () => {
        return HTTP.make(path, 'post');
      },
      index: () => {
        return HTTP.make(path, 'get');
      },
      destroy: () => {
        return HTTP.make(path, 'delete');
      },
    };
  }
}
