export const BASE_ITEM_TYPE = {
  DEFAULT: 'default',
  INFO: 'info',
  NUMBER: 'number',
  NUMBER_DANGER: 'number-danger',
  NUMBER_CHANGED: 'number-changed',
  DANGER: 'danger',
  WARNING: 'warning',
  FLAG: 'flag',
  TAG_VARIANT: 'tag-variant',
  CHANGED: 'changed',
  LINK: 'link',
} as const;
