import { HTTP } from './HTTP.js';

export class UsersService {
  constructor() {
    this.baseUrl = '/api/admin/users';
  }

  index() {
    return HTTP.make(this.baseUrl, 'get');
  }

  uniqueEmail(email) {
    const path = `${this.baseUrl}/unique-email`;
    const query = { email };
    return HTTP.make(path, 'get').data(query);
  }

  newUserMeta() {
    const path = `${this.baseUrl}/wizard`;
    return HTTP.make(path, 'get');
  }

  store(data) {
    return HTTP.make(this.baseUrl, 'post').data(data);
  }

  filters() {
    const path = `${this.baseUrl}/filters`;
    return HTTP.make(path, 'get');
  }

  getByEmail(email) {
    const path = `${this.baseUrl}/check-user`;
    return HTTP.make(path, 'get').data({ email });
  }

  publicRequests() {
    const basePath = `/api/admin/user-requests`;

    return {
      index() {
        return HTTP.make(basePath, 'get');
      },
      byId(id) {
        return HTTP.make(`${basePath}/${id}`, 'get');
      },
      accept(userId, organisationId) {
        return HTTP.make(`${basePath}/${userId}`, 'put').data({
          organisation_id: organisationId,
        });
      },
      status(token) {
        return HTTP.make(`${basePath}/validate/${token}`, 'get');
      },
      destroy(id) {
        return HTTP.make(`${basePath}/${id}`, 'delete');
      },
      store(id, form) {
        return HTTP.make(`${basePath}/${id}`, 'post').data(form);
      },
    };
  }
}
