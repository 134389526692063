import { HTTP } from './HTTP.js';

export class ProfileService {
  constructor() {
    this.base = '/api/customer/profile';
  }

  store({ firstName, lastName }) {
    return HTTP.make(this.base, 'post').data({
      _method: 'put',
      first_name: firstName,
      last_name: lastName,
    });
  }

  password() {
    return {
      store: ({ password, newPassword }) =>
        HTTP.make(this.base, 'post').data({
          _method: 'put',
          current_password: password,
          new_password: newPassword,
        }),
    };
  }
}
