import { HTTP } from './HTTP.js';

export class OrganisationCompaniesService {
  constructor(organisationId) {
    this.organisationId = organisationId;
    this.baseUrl = `/api/customer/organisations/${organisationId}/companies`;
  }

  index({ page, perPage }) {
    const path = `/api/customer/organisations/${this.organisationId}/companies-v2`;
    const query = {
      page,
      per_page: perPage,
    };

    return HTTP.make(path, 'get').data(query);
  }

  destroy(companyId) {
    const path = `${this.baseUrl}/${companyId}`;
    return HTTP.make(path, 'delete');
  }

  store(companies) {
    return HTTP.make(this.baseUrl, 'post').data({
      companies: companies.map((company) => ({
        global_address_number: company.global_address_number,
        name: company.name,
        location: company.location,
      })),
    });
  }
}
