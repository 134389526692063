import { getSearchableLane } from '~/features/useSailingSchedule';
import { isEmpty } from '~/features/useUtilities';

import type {
  IRegistrationHighlightedOption,
  IRegistrationSearchOptionType,
  IRegistrationTradelanesOptionType,
  IStatisticsTradeLane,
} from '~/types';

export const getRegistrationHighlightedOptions = (
  type: IRegistrationSearchOptionType,
  options: IRegistrationTradelanesOptionType[],
  search: string,
): IRegistrationHighlightedOption[] => {
  if (isEmpty(options)) {
    return [];
  }

  const regex = new RegExp(`(${search})`, 'i');

  return options.map((option) => {
    const text = option.name.replace(
      regex,
      '<span style="color: #408481">$&</span>',
    );

    return {
      ...option,
      type,
      inTradelanes: option?.inTradelanes || [option.id],
      highlightedName: text,
    };
  });
};

export const getRegistrationFilteredOptions = (
  options: IRegistrationTradelanesOptionType[],
  selectedOptions: IRegistrationHighlightedOption[],
  search: string,
  byId = false,
): IRegistrationTradelanesOptionType[] => {
  if (isEmpty(options)) {
    return [];
  }

  return options.filter(({ name, id }) => {
    const checkedOption = selectedOptions.find((option) => option.id === id);

    if (checkedOption) {
      return false;
    }

    const isNameMatch = name.toLowerCase().includes(search.toLowerCase());

    if (byId) {
      const isIdMatch = id.toLowerCase().includes(search.toLowerCase());

      return isIdMatch || isNameMatch;
    }

    return isNameMatch;
  });
};

export const getRegistrationFilteredSelectedTrades = (
  options: IRegistrationHighlightedOption[],
  optionId: IRegistrationHighlightedOption['id'],
): IRegistrationHighlightedOption[] =>
  options
    .map((option) => {
      return {
        ...option,
        inTradelanes: option.inTradelanes.filter((trade) => trade !== optionId),
      };
    })
    .filter((option) => option.inTradelanes.length);

export const getRegistrationFilteredSelectedOptions = (
  options: IRegistrationHighlightedOption[],
  selectedOption: IRegistrationHighlightedOption,
): IRegistrationHighlightedOption[] => {
  const filteredLanes = options
    .filter((option) => option.searched && option.id !== selectedOption.id)
    .flatMap((option) => option.inTradelanes);

  const uniqTrades = [...new Set(filteredLanes)];

  const uniqSelectedTrades = selectedOption.inTradelanes.filter(
    (trade) => !uniqTrades.includes(trade),
  );

  return options
    .filter((option) => option.id !== selectedOption.id)
    .map((option) => ({
      ...option,
      inTradelanes: option.inTradelanes.filter(
        (trade) => !uniqSelectedTrades.includes(trade),
      ),
    }))
    .filter((option) => option.inTradelanes.length);
};

export const getRegistrationSelectedOptionsByTrade = (
  options: IRegistrationHighlightedOption[],
  trades: IStatisticsTradeLane[],
) =>
  options.filter((option) =>
    option.inTradelanes.every((trade) =>
      trades.find((option) => option.id === trade),
    ),
  );

export const getUniqueRegistrationSearchTradeLanes = (
  options: IRegistrationHighlightedOption[],
) => {
  const selectedTrades = options.flatMap((option) => option.inTradelanes);

  const uniqTrades = [...new Set(selectedTrades)];

  const selectedUniqTrades = uniqTrades.map(getSearchableLane);

  return selectedUniqTrades;
};
