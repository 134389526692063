export const BUTTON_VARIANT = {
  PRIMARY: 'btn-primary',
  SECONDARY: 'btn-secondary',
  TERTIARY: 'btn-tertiary',
  WHITE: 'btn-white',
  WARNING: 'btn-warning',
} as const;

export const BUTTON_TYPE = {
  BUTTON: 'button',
  SUBMIT: 'submit',
} as const;

export const BUTTON_SIZE = {
  NONE: 'none',
  SMALL: 'btn-sm',
  MEDIUM: 'btn-md',
  LARGE: 'btn-lg',
} as const;

export const BUTTON_ICON_SIZE = {
  [BUTTON_SIZE.NONE]: '',
  [BUTTON_SIZE.SMALL]: 'h-8 px-1',
  [BUTTON_SIZE.MEDIUM]: 'h-[40px] px-2',
  [BUTTON_SIZE.LARGE]: 'h-[50px] px-[13px]',
};

export const BUTTON_TEXT_SIZE = {
  [BUTTON_SIZE.NONE]: '',
  [BUTTON_SIZE.SMALL]: 'h-8 px-4',
  [BUTTON_SIZE.MEDIUM]: 'h-[40px] px-4',
  [BUTTON_SIZE.LARGE]: 'h-[50px] px-8',
};

export const BUTTON_TEXT_WIDTH_BREAKPOINTS = {
  xxs: 'xxs:min-w-[62px]',
  '@xxs': '@xxs:min-w-[62px]',
  xs: 'xs:min-w-[62px]',
  '@xs': '@xs:min-w-[62px]',
  sm: 'sm:min-w-[62px]',
  '@sm': '@sm:min-w-[62px]',
  md: 'md:min-w-[62px]',
  '@md': '@md:min-w-[62px]',
  lg: 'lg:min-w-[62px]',
  '@lg': '@lg:min-w-[62px]',
  xl: 'xl:min-w-[62px]',
  '@xl': '@xl:min-w-[62px]',
  '2xl': '2xl:min-w-[62px]',
  '@2xl': '@2xl:min-w-[62px]',
  '4xl': '4xl:min-w-[62px]',
  '@4xl': '@4xl:min-w-[62px]',
  desktop: 'desktop:min-w-[62px]',
  '@desktop': '@desktop:min-w-[62px]',
};

export const BUTTON_TEXT_SIZE_LG_BREAKPOINTS = {
  xxs: 'xxs:h-[50px] xxs:px-8',
  '@xxs': '@xxs:h-[50px] @xxs:px-8',
  xs: 'xs:h-[50px] xs:px-8',
  '@xs': '@xs:h-[50px] @xs:px-8',
  sm: 'sm:h-[50px] sm:px-8',
  '@sm': '@sm:h-[50px] @sm:px-8',
  md: 'md:h-[50px] md:px-8',
  '@md': '@md:h-[50px] @md:px-8',
  lg: 'lg:h-[50px] lg:px-8',
  '@lg': '@lg:h-[50px] @lg:px-8',
  xl: 'xl:h-[50px] xl:px-8',
  '@xl': '@xl:h-[50px] @xl:px-8',
  '2xl': '2xl:h-[50px] 2xl:px-8',
  '@2xl': '@2xl:h-[50px] @2xl:px-8',
  '4xl': '4xl:h-[50px] 4xl:px-8',
  '@4xl': '@4xl:h-[50px] @4xl:px-8',
  desktop: 'desktop:h-[50px] desktop:px-8',
  '@desktop': '@desktop:h-[50px] @desktop:px-8',
};

export const BUTTON_TEXT_SIZE_MD_BREAKPOINTS = {
  xxs: 'xxs:h-[40px] xxs:px-4',
  '@xxs': '@xxs:h-[40px] @xxs:px-4',
  xs: 'xs:h-[40px] xs:px-4',
  '@xs': '@xs:h-[40px] @xs:px-4',
  sm: 'sm:h-[40px] sm:px-4',
  '@sm': '@sm:h-[40px] @sm:px-4',
  md: 'md:h-[40px] md:px-4',
  '@md': '@md:h-[40px] @md:px-4',
  lg: 'lg:h-[40px] lg:px-4',
  '@lg': '@lg:h-[40px] @lg:px-4',
  xl: 'xl:h-[40px] xl:px-4',
  '@xl': '@xl:h-[40px] @xl:px-4',
  '2xl': '2xl:h-[40px] 2xl:px-4',
  '@2xl': '@2xl:h-[40px] @2xl:px-4',
  '4xl': '4xl:h-[40px] 4xl:px-4',
  '@4xl': '@4xl:h-[40px] @4xl:px-4',
  desktop: 'desktop:h-[40px] desktop:px-4',
  '@desktop': '@desktop:h-[40px] @desktop:px-4',
};

export const BUTTON_TEXT_SIZE_SM_BREAKPOINTS = {
  xxs: 'xxs:h-8 xxs:px-4',
  '@xxs': '@xxs:h-8 @xxs:px-4',
  xs: 'xs:h-8 xs:px-4',
  '@xs': '@xs:h-8 @xs:px-4',
  sm: 'sm:h-8 sm:px-4',
  '@sm': '@sm:h-8 @sm:px-4',
  md: 'md:h-8 md:px-4',
  '@md': '@md:h-8 @md:px-4',
  lg: 'lg:h-8 lg:px-4',
  '@lg': '@lg:h-8 @lg:px-4',
  xl: 'xl:h-8 xl:px-4',
  '@xl': '@xl:h-8 @xl:px-4',
  '2xl': '2xl:h-8 2xl:px-4',
  '@2xl': '@2xl:h-8 @2xl:px-4',
  '4xl': '4xl:h-8 4xl:px-4',
  '@4xl': '@4xl:h-8 @4xl:px-4',
  desktop: 'desktop:h-8 desktop:px-4',
  '@desktop': '@desktop:h-8 @desktop:px-4',
};

export const BUTTON_TEXT_SIZE_MAP = {
  [BUTTON_SIZE.SMALL]: BUTTON_TEXT_SIZE_SM_BREAKPOINTS,
  [BUTTON_SIZE.MEDIUM]: BUTTON_TEXT_SIZE_MD_BREAKPOINTS,
  [BUTTON_SIZE.LARGE]: BUTTON_TEXT_SIZE_LG_BREAKPOINTS,
};

export const BUTTON_TEXT_BUTTON_BREAKPOINTS = {
  xxs: 'max-xss:hidden',
  '@xxs': '@max-xss:hidden',
  xs: 'max-xs:hidden',
  '@xs': '@max-xs:hidden',
  sm: 'max-sm:hidden',
  '@sm': '@max-sm:hidden',
  md: 'max-md:hidden',
  '@md': '@max-md:hidden',
  lg: 'max-lg:hidden',
  '@lg': '@max-lg:hidden',
  xl: 'max-xl:hidden',
  '@xl': '@max-xl:hidden',
  '2xl': 'max-2xl:hidden',
  '@2xl': '@max-2xl:hidden',
  '4xl': 'max-4xl:hidden',
  '@4xl': '@max-4xl:hidden',
  desktop: 'max-desktop:hidden',
  '@desktop': '@max-desktop:hidden',
} as const;

export const BUTTON_ICON_BREAKPOINTS = {
  xxs: 'xxs:hidden',
  '@xxs': '@xxs:hidden',
  xs: 'xs:hidden',
  '@xs': '@xs:hidden',
  sm: 'sm:hidden',
  '@sm': '@sm:hidden',
  md: 'md:hidden',
  '@md': '@md:hidden',
  lg: 'lg:hidden',
  '@lg': '@lg:hidden',
  xl: 'xl:hidden',
  '@xl': '@xl:hidden',
  '2xl': '2xl:hidden',
  '@2xl': '@2xl:hidden',
  '4xl': '4xl:hidden',
  '@4xl': '@4xl:hidden',
  desktop: 'desktop:hidden',
  '@desktop': '@desktop:hidden',
} as const;
