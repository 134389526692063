export const USER_TYPES = {
  G2O: 'g2o',
  CUSTOMER: 'customer',
  PUBLIC: 'public',
} as const;

export const ROLES = {
  G2O_ADMIN: 'G2O Admin',
  G2O_USER: 'G2O User',
  G2O_SPECTATOR: 'G2O Spectator',
  CUSTOMER_ADMIN: 'Admin',
  CUSTOMER_STANDARD: 'Standard',
} as const;
