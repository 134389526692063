import { CATEGORICAL_DAVIZ_COLORS_TO_TAG } from '.';

export const BASE_TAG_LOW_CONTRAST = {
  GREEN_1: 'contrast-green-1',
  AMBER_1: 'contrast-amber-1',
  CHARCOAL_1: 'contrast-charcoal-1',
  PURPLE_1: 'contrast-purple-1',
  NAVY_BLUE_1: 'contrast-navy-blue-1',
  INDIGO_1: 'contrast-indigo-1',
  MAGENTA_1: 'contrast-magenta-1',
  ORANGE_1: 'contrast-orange-1',
} as const;

export const BASE_TAG_HIGH_CONTRAST = {
  ...CATEGORICAL_DAVIZ_COLORS_TO_TAG,
  GREEN_6: 'contrast-green-6',
  AMBER_1: 'contrast-amber-1',
  OCEAN_3: 'contrast-ocean-3',
  OCEAN_6: 'contrast-ocean-6',
  NAVY_BLUE_4: 'contrast-navy-blue-4',
  GREEN_5: 'contrast-green-5',
  PINK_3: 'contrast-pink-3',
  CHARCOAL_2: 'contrast-charcoal-2',
} as const;

export const BASE_TAG_CONTRAST = {
  ...BASE_TAG_LOW_CONTRAST,
  ...BASE_TAG_HIGH_CONTRAST,
} as const;
