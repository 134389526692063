import { AuthService } from './AuthService.js';
import { HTTP } from './HTTP.js';
import { OrganisationService } from './OrganisationService.js';
import { ProfileService } from './ProfileService.js';
import { PublicUserService } from './PublicUserService';
import { UserService } from './UserService.js';
import { UsersService } from './UsersService.js';

export default class Service {
  static organisation(id) {
    return new OrganisationService(id);
  }

  static auth() {
    return new AuthService();
  }

  static websocket() {
    return {
      connect: (socketId, channelName) => {
        const path = '/api/broadcasting/auth';
        return HTTP.make(path, 'post').data({
          socket_id: socketId,
          channel_name: channelName,
        });
      },
    };
  }

  static healthStatus() {
    return {
      public: () => {
        const path = '/api/system-health';
        return HTTP.make(path, 'get');
      },
      internal: () => {
        const path = '/api/customer/system-health';
        return HTTP.make(path, 'get');
      },
    };
  }

  static publicBol() {
    return {
      index: () => {
        const path = `/api/public-bol-shipment`;
        return HTTP.make(path, 'get');
      },
    };
  }

  static companies() {
    return {
      index: (query) => {
        const path = '/api/admin/companies';
        return HTTP.make(path, 'get').data(query);
      },
    };
  }

  static organisations() {
    return {
      store: (name) => {
        const path = '/api/customer/organisations';
        return HTTP.make(path, 'post').data({ name });
      },
    };
  }

  static organisationList() {
    return {
      index: () => {
        const path = 'api/admin/organisations';
        return HTTP.make(path, 'get');
      },
      store: (data) => {
        const path = 'api/admin/organisations';
        return HTTP.make(path, 'post').data(data);
      },
      destroy: (id) => {
        const path = `api/admin/organisations/${id}`;
        return HTTP.make(path, 'delete');
      },
      newOrganisationMeta: () => {
        const path = 'api/admin/organisations/wizard';
        return HTTP.make(path, 'get');
      },
      uniqueName: (name, id) => {
        const path = 'api/admin/organisations/unique-name';
        return HTTP.make(path, 'get').data({ name, organisation_id: id });
      },
    };
  }

  static sharedShipment() {
    return {
      index: (id) => {
        const path = `/api/shared-shipments/${id}`;
        return HTTP.make(path, 'get');
      },
      tracking: (id) => {
        const path = `api/shared-shipments/${id}/tracking`;
        return HTTP.make(path, 'get');
      },
    };
  }

  static notifications() {
    return {
      index: () => {
        const path = '/api/customer/notifications';
        return HTTP.make(path, 'get');
      },
      update: (id) => {
        const path = `/api/customer/notifications/${id}`;
        return HTTP.make(path, 'put');
      },
      markAllAsRead: () => {
        const path = '/api/customer/notifications/mark-all-as-read';
        return HTTP.make(path, 'put');
      },
    };
  }

  static profile() {
    return new ProfileService();
  }

  static user(id) {
    return new UserService(id);
  }

  static users() {
    return new UsersService();
  }

  static publicUser() {
    return new PublicUserService();
  }

  static publicSailingSchedule() {
    const baseUrl = 'api/public/sailing-schedule';
    return {
      index: () => {
        const path = baseUrl;
        return HTTP.make(path, 'get');
      },
      indexMobile: () => {
        const path = `${baseUrl}/mobile`;
        return HTTP.make(path, 'get');
      },
      filters: () => {
        const path = `${baseUrl}/filters`;
        return HTTP.make(path, 'get');
      },
      voyages: (trade) => ({
        index: () => {
          const path = `${baseUrl}/${trade}`;
          return HTTP.make(path, 'get');
        },
        download: () => {
          const path = `${baseUrl}/${trade}/download`;
          return HTTP.make(path, 'post');
        },
      }),
      customize: () => {
        const path = `${baseUrl}/customization`;

        return {
          index: () => HTTP.make(path, 'get'),
          tradelanes: (search) =>
            HTTP.make(`${path}/tradelanes`, 'get').data({ search }),
          store: (id) =>
            HTTP.make(path, 'post').data({
              tradelanes: [id],
            }),
          destroy: (id) => HTTP.make(path, 'delete').data({ tradelane: id }),
        };
      },
    };
  }

  static publicVessels() {
    const baseUrl = 'api/public/vessels';

    return {
      index: () => {
        const path = baseUrl;
        return HTTP.make(path, 'get');
      },
      show: (id) => {
        const path = `${baseUrl}/${id}`;
        return HTTP.make(path, 'get');
      },
    };
  }

  static publicUserCargo() {
    return {
      index: () => {
        return HTTP.make('api/public/cargo-tracker', 'get');
      },
    };
  }
}
