import { HTTP } from './HTTP.js';

export class UserService {
  constructor(id) {
    this.baseUrl = `/api/admin/users/${id}`;
  }

  show() {
    return HTTP.make(this.baseUrl, 'get');
  }

  destroy() {
    return HTTP.make(this.baseUrl, 'delete');
  }

  update(data) {
    return HTTP.make(this.baseUrl, 'put').data(data);
  }

  organisation({ search, page, perPage }) {
    const path = `${this.baseUrl}/organisations`;
    return {
      show: () =>
        HTTP.make(path, 'get').data({
          search,
          page,
          per_page: perPage,
        }),
    };
  }

  organisationsV2() {
    const path = `${this.baseUrl}/organisations`;
    return {
      show: () => HTTP.make(path, 'get'),
      update: () => {
        return HTTP.make(path, 'post');
      },
    };
  }
}
