import { HTTP } from './HTTP.js';
import { CargoBookingsService } from './OrganisationCargoBookingsService';
import { OrganisationCompaniesService } from './OrganisationCompaniesService.js';
import { OrganisationKpiService } from './OrganisationKpiService';
import { OrganisationRolesService } from './OrganisationRolesService.js';
import { ShipmentsService } from './OrganisationShipmentsService.js';
import { OrganisationStatisticsService } from './OrganisationStatisticsService.js';
import { OrganisationUsersService } from './OrganisationUsersService.js';

export class OrganisationService {
  /**
   * @param {string} id
   */
  constructor(id) {
    this.id = id;
    this.baseUrl = `/api/customer/organisation/${id}`;
  }

  edit() {
    const path = `/api/admin/organisations/${this.id}`;
    return HTTP.make(path, 'get');
  }

  update(data) {
    const path = `/api/admin/organisations/${this.id}`;

    return HTTP.make(path, 'post').data({
      _method: 'put',
      ...data,
    });
  }
  home() {
    const path = `${this.baseUrl}/home`;

    return {
      show: () => HTTP.make(`${path}`, 'get'),
      widgets: () => HTTP.make(`${path}/statistics`, 'get'),
      customize: () => ({
        show: () => HTTP.make(`${path}/statistics/settings`, 'get'),
        update: () => HTTP.make(`${path}/statistics/settings`, 'post'),
      }),
    };
  }

  show() {
    return HTTP.make(`${this.baseUrl}/home`, 'get');
  }

  destroy() {
    return HTTP.make(`/api/customer/organisations/${this.id}`, 'delete');
  }

  companies() {
    return new OrganisationCompaniesService(this.id);
  }

  roles() {
    return new OrganisationRolesService(this.id);
  }

  users() {
    return new OrganisationUsersService(this.id);
  }

  cargoBookings() {
    return new CargoBookingsService(this.id);
  }

  /**
   * @param {string | undefined} id
   */

  shipment(id = undefined) {
    return new ShipmentsService(this.id, id);
  }

  statistics() {
    return new OrganisationStatisticsService(this.id);
  }

  kpis() {
    return new OrganisationKpiService(this.id);
  }

  features() {
    const path = `/api/customer/organisations/${this.id}/settings`;
    return {
      index: () => HTTP.make(path, 'get'),
      /**
       * @param {Object} payload
       * @param {String} payload.name
       * @param {Boolean} payload.status
       */
      update: (payload) => HTTP.make(path, 'post').data(payload),
    };
  }

  sailingScheduleV2() {
    const baseUrl = `${this.baseUrl}/sailing-schedule-v2`;

    return {
      index: () => {
        const path = `${baseUrl}`;
        return HTTP.make(path, 'get');
      },
      indexMobile: () => {
        const path = `${baseUrl}/mobile`;
        return HTTP.make(path, 'get');
      },
      filters: () => {
        const path = `${baseUrl}/filters`;
        return HTTP.make(path, 'get');
      },
      voyages: (trade) => ({
        index: () => {
          const path = `${baseUrl}/${trade}`;
          return HTTP.make(path, 'get');
        },
        download: () => {
          const path = `${baseUrl}/${trade}/download`;
          return HTTP.make(path, 'post');
        },
      }),
    };
  }

  vessels() {
    return {
      index: () => {
        const path = `${this.baseUrl}/vessels`;
        return HTTP.make(path, 'get');
      },
      show: (id) => {
        const path = `${this.baseUrl}/vessels/${id}`;
        return HTTP.make(path, 'get');
      },
    };
  }
}
