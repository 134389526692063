export const emailIsG2Ocean = (email?: string | null) => {
  if (!email) return false;

  return (
    email.toLowerCase().endsWith('@g2ocean.com') ||
    email.toLowerCase().endsWith('@worldharbour.onmicrosoft.com')
  );
};

export const useAzureLogin = () => {
  const onLogin = (email: string, cb: () => void) => {
    if (!emailIsG2Ocean(email)) {
      cb();
      return;
    }

    const url = new URL(
      `${import.meta.env.VITE_API_TARGET}/auth/azure/redirect`,
    );

    url.searchParams.append('email', email);

    window.location.href = url.toString();
  };

  return { onLogin };
};
