export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'v2_access_token',

  PROFILE: 'v2_user_profile',
  PERMISSIONS: 'v2_internal_permissions',
  TOUR_COMPLETED: 'v2_tour_completed',

  GOOGLE_ENABLED: 'v2_google_is_enabled',
  USER_LIST_QUERY: 'v2_user_list_filters',
  PENDING_USER_REQUESTS: 'v2_pending_user_requests',
  ORGANISATION_USER_LIST_QUERY: 'v2_organisation_user_list_query',
  SAILING_SCHEDULE_QUERY: 'v2_sailing_schedule_filters',
  NOMINATION_TRADELANE_FILTERS: 'v2_nomination_tradelane_filters',
  PUBLIC_SAILING_SCHEDULE_QUERY: 'sailing_schedule_filters.public',
  SAILING_SCHEDULE_TIME_ESTIMATE: 'v2_sailing_schedule_time_estimate',
  COOKIE_LAW: 'v2_cookie_law_state',

  ORGANISATION: 'v2_selected_organisation',
  ORGANISATIONS: 'v2_user_organisations',
  UNREAD_NOTIFICATIONS: 'v2_unread_notification_count',
  MAINTENANCE: 'v2_maintenance',
} as const;
