import Axios from 'axios';
import { hasFiles, objectToFormData } from '~/features/useFormData';
import { storage } from '~/features/useStorage';
import {
  hrefToUrl,
  mergeDataIntoQueryString,
  urlWithoutHash,
} from '~/features/useUrl';

export class HTTP {
  #activeVisit = null;

  #forceFormData = false;

  #method = 'get';

  #url = '';

  #data = {};

  #headers = {};

  #config = {};

  #methods = {
    onBefore: null,
    onCancelToken: null,
    onStart: null,
    onSuccess: null,
    onNoData: null,
    onCancel: null,
    onFinish: null,
    onError: null,
    onClientError: null,
    onErrorAuth: null,
    onErrorNotFound: null,
    onErrorValidation: null,
    onErrorNotAvailable: null,
    onErrorConflict: null,
  };

  /**
   * @param {string} url
   * @param {"post"|"put"|"delete"|"get"} method
   */
  static make(url, method) {
    return new HTTP(url, method);
  }

  static throwError(visit, cb, error) {
    if (visit[cb]) {
      visit[cb](error);
      return;
    }
    visit.onError?.(error);
  }

  /**
   * @param {string} url
   * @param {"post"|"put"|"delete"|"get"} method
   */
  constructor(url, method = 'get') {
    this.#url = hrefToUrl(url);
    this.#method = method;
  }

  /**
   * @param {Function} cb
   */
  onBefore(cb) {
    this.#methods.onBefore = cb;
    return this;
  }

  /**
   * @param {Function} cb
   */
  onCancelToken(cb) {
    this.#methods.onCancelToken = cb;
    return this;
  }

  /**
   * @param {Function} cb
   */
  onStart(cb) {
    this.#methods.onStart = cb;
    return this;
  }

  /**
   * @param {Function} cb
   */
  onSuccess(cb) {
    this.#methods.onSuccess = cb;
    return this;
  }

  /**
   * @param {Function} cb
   */
  onNoData(cb) {
    this.#methods.onNoData = cb;
    return this;
  }

  /**
   * @param {Function} cb
   */
  onFinish(cb) {
    this.#methods.onFinish = cb;
    return this;
  }

  /**
   * @param {Function} cb
   */
  onCancel(cb) {
    this.#methods.onCancel = cb;
    return this;
  }

  /**
   * @param {Function} cb
   */
  onError(cb) {
    this.#methods.onError = cb;
    return this;
  }

  /**
   * @param {Function} cb
   */
  onClientError(cb) {
    this.#methods.onClientError = cb;
    return this;
  }

  /**
   * @param {Function} cb
   */
  onErrorAuth(cb) {
    this.#methods.onErrorAuth = cb;
    return this;
  }

  /**
   * @param {Function} cb
   */
  onErrorConflict(cb) {
    this.#methods.onErrorConflict = cb;
    return this;
  }

  /**
   * @param {Function} cb
   */
  onErrorNotAvailable(cb) {
    this.#methods.onErrorNotAvailable = cb;
    return this;
  }

  /**
   * @param {Function} cb
   */
  onErrorNotFound(cb) {
    this.#methods.onErrorNotFound = cb;
    return this;
  }

  /**
   * @param {Function} cb
   */
  onErrorValidation(cb) {
    this.#methods.onErrorValidation = cb;
    return this;
  }

  /**
   * @param {object} data
   */
  data(data) {
    this.#data = data;
    return this;
  }

  /**
   * @param {object} headers
   */
  headers(headers) {
    this.#headers = headers;
    return this;
  }

  /**
   * @param {object} config
   */
  config(config) {
    this.#config = config;
    return this;
  }

  /**
   * @param {Boolean} forceFormData
   */
  forceFormData(forceFormData) {
    this.#forceFormData = forceFormData;
    return this;
  }

  cancelOnNewRequest(tokenRef) {
    this.onBefore(() => {
      if (tokenRef.value) tokenRef.value.cancel();
    });

    this.onCancelToken((token) => {
      tokenRef.value = token;
    });

    return this;
  }

  execute() {
    return this.visit();
  }

  cancelVisit({ cancelled = false, interrupted = false }) {
    const visit = this.#activeVisit;
    if (visit && !visit.completed && !visit.cancelled && !visit.interrupted) {
      visit.cancelToken.cancel();
      visit.onCancel?.();
      visit.completed = false;
      visit.cancelled = cancelled;
      visit.interrupted = interrupted;

      visit.onFinish?.(visit);
    }
  }

  async visit() {
    const method = this.#method;

    // This removes search params that could be cached;
    this.#url = new URL(`${this.#url.origin}${this.#url.pathname}`);
    // eslint-disable-next-line prefer-const
    let [url, data] = mergeDataIntoQueryString(method, this.#url, this.#data);

    const visitHasFiles = hasFiles(data);

    if (this.#method !== 'get' && (visitHasFiles || this.#forceFormData))
      data = objectToFormData(data);

    const visit = {
      url,
      method,
      data,
      headers: this.#headers,
      forceFormData: this.#forceFormData,
      ...this.#methods,
    };

    if (visit.onBefore?.(visit) === false) return;

    this.#activeVisit = visit;
    this.#activeVisit.cancelToken = Axios.CancelToken.source();
    visit.onCancelToken?.({
      cancel: () => this.cancelVisit({ cancelled: true }),
    });

    visit.onStart?.(visit);

    try {
      const response = await Axios({
        method,
        url: urlWithoutHash(url).href,
        baseURL: import.meta.env.VITE_API_ENDPOINT,
        data: method === 'get' ? {} : data,
        params: method === 'get' ? data : {},
        cancelToken: this.#activeVisit.cancelToken.token,
        headers: {
          ...this.#headers,
          common: {
            'X-Requested-With': 'XMLHttpRequest',
            Accept: 'application/json',
            Authorization: storage.getAccessToken(),
          },
        },
        ...this.#config,
      });

      if (!response) {
        throw new Error('No response');
      }

      if (visit.onNoData && response.status === 204) {
        visit.onNoData?.(visit);
        return;
      }

      visit.onSuccess?.(response.data);
      return;
    } catch (error) {
      if (Axios.isCancel(error)) return;

      if (!error.response) {
        // Something happened in setting up the request and triggered an Error
        // or no response was received
        // hence we rethrow this error.
        visit.onError?.(error);
        return;
      }

      const { status } = error.response;

      if (status === 400) {
        HTTP.throwError(visit, 'onClientError', error);
        return;
      }

      if (status === 403) {
        HTTP.throwError(visit, 'onErrorAuth', error);
        return;
      }

      if (status === 409) {
        HTTP.throwError(visit, 'onErrorConflict', error);
        return;
      }

      if (status === 410) {
        HTTP.throwError(visit, 'onErrorNotAvailable', error);
        return;
      }

      if (status === 404) {
        HTTP.throwError(visit, 'onErrorNotFound', error);
        return;
      }

      if (status === 422) {
        HTTP.throwError(visit, 'onErrorValidation', error);
        return;
      }

      visit.onError?.(error);
    } finally {
      if (!(visit.cancelled || visit.interrupted)) {
        visit.completed = true;
        visit.cancelled = false;
        visit.interrupted = false;
        visit.onFinish?.(visit);
      }
    }
  }
}
