export const STATES = {
  IDLE: 'IDLE',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  NO_DATA: 'NO_DATA',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  NO_RESULTS: 'NO_RESULTS',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
};
