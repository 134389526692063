export const AVATAR_VARIANT = {
  OCEAN_0: 'ocean-0',
  ORANGE_0: 'orange-0',
  PURPLE_0: 'purple-0',
  BLUE_0: 'navy-blue-0',
  MAGENTA_0: 'magenta-0',
  INDIGO_0: 'indigo-0',
  GREEN_0: 'green-0',
  RED_0: 'red-0',
  AMBER_0: 'amber-0',
  CHESTNUT_0: 'chestnut-0',
  PINK_0: 'pink-0',
  OCEAN_1: 'ocean-1',
  ORANGE_1: 'orange-1',
  INDIGO_1: 'indigo-1',
} as const;

export const AVATAR_VARIANT_LIST = Object.values(AVATAR_VARIANT);

export const AVATAR_SIZES = {
  SMALL: 'sm',
  DEFAULT: 'default',
  MEDIUM: 'md',
  LARGE: 'lg',
} as const;

export const AVATAR_PENDING = 'pending' as const;
