import { HTTP } from './HTTP.js';

export class OrganisationUsersService {
  constructor(organisationId) {
    this.organisationId = organisationId;
    this.baseUrl = `/api/customer/organisations/${organisationId}/users`;
  }

  index() {
    const path = `${this.baseUrl}-v2`;
    return HTTP.make(path, 'get');
  }

  store(form) {
    return HTTP.make('/api/admin/invitations', 'post').data({
      email: form.email,
      organizations: [
        {
          id: this.organisationId,
          role_id: form.role?.id,
        },
      ],
    });
  }

  filters() {
    const path = `/api/customer/organisations/${this.organisationId}/users-v2/filters`;
    return HTTP.make(path, 'get');
  }

  storeV2(form) {
    const pathV2 = `/api/customer/organisations/${this.organisationId}/users-v2`;
    return HTTP.make(pathV2, 'post').data({
      email: form.email,
      role_id: form.role?.id,
    });
  }

  createV2() {
    const pathV2 = `/api/customer/organisations/${this.organisationId}/users-v2/wizard`;
    return HTTP.make(pathV2, 'get');
  }

  user(id) {
    const path = `${this.baseUrl}/${id}`;
    const pathV2 = `/api/customer/organisations/${this.organisationId}/users-v2/${id}`;
    return {
      show: () => HTTP.make(path, 'get'),
      showV2: () => HTTP.make(pathV2, 'get'),
      destroy: () => HTTP.make(path, 'delete'),
      destroyV2: () => HTTP.make(pathV2, 'delete'),
      update: ({ firstName, lastName, role }) =>
        HTTP.make(path, 'put').data({
          first_name: firstName,
          last_name: lastName,
          role,
        }),
      updateV2: (data) => HTTP.make(pathV2, 'put').data(data),
    };
  }
}
