import type {
  IInvoicesForm,
  IInvoicesKpiQuery,
  IKpiType,
  IShipmentVolumesForm,
  IStatisticsKpiQuery,
  ITransitTimesKpiFormSubmit,
  KpiPerShipmentViewQueryType,
} from '~/types';

import { HTTP } from './HTTP';

export class OrganisationKpiService {
  organisationId: number;
  baseOrgPath: string;
  baseOrgsPath: string;
  baseMockPath: string;

  constructor(organisationId: number) {
    this.organisationId = organisationId;
    this.baseOrgPath = `/api/customer/organisation/${this.organisationId}`;
    this.baseMockPath = '/api/customer/organisations/1';
    this.baseOrgsPath = `/api/customer/organisations/${this.organisationId}`;
  }

  index() {
    return HTTP.make(`${this.baseOrgPath}/kpis`, 'get');
  }

  kpi(contractId) {
    const path = `${this.baseOrgsPath}/kpi-settings/${contractId}`;

    return {
      show: () => HTTP.make(path, 'get'),
      update: () => HTTP.make(path, 'put'),
      destroyKpi: () => {
        return HTTP.make(path, 'delete');
      },
      updateVisibility: ({ type, visible }) => {
        return HTTP.make(`${path}/category/${type}?visible=${visible}`, 'put');
      },
      destroyCategory: (type) => {
        return HTTP.make(`${path}/category/${type}`, 'delete');
      },
    };
  }

  settings() {
    const settingsPath = `${this.baseOrgsPath}/kpi-settings`;

    return {
      index: () => HTTP.make(settingsPath, 'get'),
      create: () => HTTP.make(settingsPath, 'post'),
      destroy: (type: IKpiType, id: string) =>
        HTTP.make(`${settingsPath}/${type}/${id}`, 'delete'),
      byType: (type: IKpiType) => HTTP.make(`${settingsPath}/${type}`, 'get'),
    };
  }

  wizard() {
    const wizardPath = `${this.baseOrgsPath}/kpi-settings/wizard`;

    return {
      index: (data) => HTTP.make(wizardPath, 'get').data(data),
      ports: (data) => HTTP.make(`${wizardPath}/ports`, 'get').data(data),
    };
  }

  transitTimes() {
    const kpiPath = `${this.baseOrgsPath}/kpi-settings/transitTime`;
    return {
      wizard: () => {
        const path = `${kpiPath}/wizard`;

        return HTTP.make(path, 'get');
      },
      store: (form: ITransitTimesKpiFormSubmit) => {
        const path = `${kpiPath}`;

        return HTTP.make(path, 'post').data(form);
      },
      show: (id: string) => HTTP.make(`${kpiPath}/${id}`, 'get'),
      edit: (id: string) => {
        const path = `${kpiPath}/${id}/edit`;

        return HTTP.make(path, 'get');
      },
      update: (id: string, form: ITransitTimesKpiFormSubmit) => {
        const path = `${kpiPath}/${id}`;

        return HTTP.make(path, 'post').data({
          ...form,
          _method: 'put',
        });
      },
      chart: () => {
        const path = `${this.baseOrgPath}/kpis/transitTime`;

        return {
          index: (query: IStatisticsKpiQuery) =>
            HTTP.make(`${path}`, 'get').data({
              contract: query.contracts.map((contract) => contract.id),
              trade: query.tradeLanes.map((lane) => lane.id),
              status: query.statuses.map((status) => status.id),
            }),
          getByViewOption: (view, categoryId) =>
            HTTP.make(`${path}/${categoryId}`, 'get').data({
              view,
            }),
          filters: () => HTTP.make(`${path}/filters`, 'get'),
        };
      },
    };
  }

  shipmentVolumes() {
    const kpiPath = `${this.baseOrgsPath}/kpi-settings/shipmentVolumes`;

    const getFormData = (form: IShipmentVolumesForm) => ({
      ...form,
      trade_contract: form['trade-contract'],
      kpi_targets: form.kpi_targets.map((target) => {
        const [period_start, period_end] = target.period;
        return {
          period_start,
          period_end,
          period_min_volume: target.period_min_volume,
          period_max_volume: target.period_max_volume,
          shipment_min_volume: target.shipment_min_volume,
          shipment_max_volume: target.shipment_max_volume,
        };
      }),
    });

    return {
      wizard: () => {
        const path = `${kpiPath}/wizard`;

        return HTTP.make(path, 'get');
      },
      index: () => {
        const path = `${kpiPath}`;

        return HTTP.make(path, 'get');
      },
      store: (form: IShipmentVolumesForm) => {
        const path = `${kpiPath}`;

        return HTTP.make(path, 'post').data(getFormData(form));
      },
      show: (id: number) => {
        const path = `${kpiPath}/${id}`;

        return HTTP.make(path, 'get');
      },
      edit: (id: string) => {
        const path = `${kpiPath}/${id}/edit`;

        return HTTP.make(path, 'get');
      },
      update: (id: number, form: IShipmentVolumesForm) => {
        const path = `${kpiPath}/${id}`;

        return HTTP.make(path, 'post').data({
          ...getFormData(form),
          _method: 'put',
        });
      },
      destroy: (id: number) => {
        const path = `${kpiPath}/${id}`;

        return HTTP.make(path, 'delete');
      },
      periodChart: () => {
        const path = `${this.baseOrgPath}/kpis/shipmentVolumes/per_period`;

        return {
          index: (query) =>
            HTTP.make(path, 'get').data({
              contract: query.contracts.map((contract) => contract.id),
              trade: query.tradeLanes.map((lane) => lane.id),
              status: query.statuses.map((status) => status.id),
            }),
          filters: () => HTTP.make(`${path}/filters`, 'get'),
        };
      },
      perShipmentChart: () => {
        const path = `${this.baseOrgPath}/kpis/shipmentVolumes/per_shipment`;

        return {
          index: (query) =>
            HTTP.make(path, 'get').data({
              contract: query.contracts.map((contract) => contract.id),
              trade: query.tradeLanes.map((lane) => lane.id),
              status: query.statuses.map((status) => status.id),
            }),
          filters: () => HTTP.make(`${path}/filters`, 'get'),
          getByCategory: (categoryId, view) =>
            HTTP.make(`${path}/${categoryId}`, 'get').data({
              view,
            }),
        };
      },
    };
  }

  invoices() {
    const kpiPath = `${this.baseOrgsPath}/kpi-settings/invoices`;

    const getFormData = (form: IInvoicesForm) => {
      const [period_start, period_end] = form.period;

      return {
        period_start,
        period_end,
        days: Number(form.days),
        visible: form.visible,
      };
    };

    return {
      store: (form: IInvoicesForm) => {
        const path = `${kpiPath}`;

        return HTTP.make(path, 'post').data(getFormData(form));
      },
      index: () => {
        const path = `${kpiPath}`;

        return HTTP.make(path, 'get');
      },
      destroy: (id: string) => {
        const path = `${kpiPath}/${id}`;

        return HTTP.make(path, 'delete');
      },
      update: (id: number, form: IInvoicesForm) => {
        const path = `${kpiPath}/${id}`;

        return HTTP.make(path, 'post').data({
          ...getFormData(form),
          _method: 'put',
        });
      },
      edit: (id: number) => {
        const path = `${kpiPath}/${id}/edit`;

        return HTTP.make(path, 'get');
      },
      chart: () => {
        const path = `${this.baseOrgPath}/kpis/invoices`;

        return {
          index: (query: IInvoicesKpiQuery) =>
            HTTP.make(`${path}`, 'get').data({
              contract: query.contracts.map((contract) => contract.id),
              status: query.statuses.map((status) => status.id),
            }),
          getByViewOption: (
            view: KpiPerShipmentViewQueryType,
            categoryId: number,
          ) =>
            HTTP.make(`${path}/${categoryId}`, 'get').data({
              view,
            }),
          filters: () => HTTP.make(`${path}/filters`, 'get'),
        };
      },
    };
  }
}
