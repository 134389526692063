import { removeNullProperties } from '~/features/useUtilities';

import { HTTP } from './HTTP.js';

export class OrganisationStatisticsService {
  constructor(organisationId) {
    this.organisationId = organisationId;
    this.baseUrl = `/api/customer/organisation/${organisationId}/charts`;
    this.baseKpiUrl = `/api/customer/organisation/${organisationId}/kpis`;
  }

  filtersMeta(lastFourYears = false) {
    const path = `${this.baseUrl}/shipment_filters${
      lastFourYears ? '?type=4y' : ''
    }`;

    return HTTP.make(path, 'get');
  }

  shipmentRevenueTons({
    period,
    tradeLanes,
    loadingPorts,
    dischargePorts,
    commodities,
    page,
    perPage,
  }) {
    const path = `${this.baseUrl}/shipment_volume`;

    const query = removeNullProperties({
      trade: tradeLanes.map((trade) => trade.id),
      commodity: commodities,
      period: period.id,
      cp_start: period.cp_start,
      cp_end: period.cp_end,
      loading_port: loadingPorts.map((port) => port.id),
      discharge_port: dischargePorts.map((port) => port.id),
      per_page: perPage,
      page,
    });

    return HTTP.make(path, 'get').data(query);
  }

  laycanPerformance() {
    const path = `${this.baseUrl}/shipment_laycan`;

    const getFormattedQuery = ({
      period,
      tradeLanes,
      loadingPorts,
      page,
      perPage,
    }) =>
      removeNullProperties({
        trade: tradeLanes.map((trade) => trade.id),
        loading_port: loadingPorts.map((port) => port.id),
        period: period.id,
        cp_start: period.cp_start,
        cp_end: period.cp_end,
        per_page: perPage,
        page,
      });

    return {
      get: (data) => {
        return HTTP.make(path, 'get').data(getFormattedQuery(data));
      },
      download: (data) => {
        return HTTP.make(`${path}/download`, 'get').data(
          getFormattedQuery(data),
        );
      },
      widgets: () => {
        return HTTP.make(`${path}/widgets`, 'get');
      },
    };
  }

  shortestTransitTime(period) {
    const path = `${this.baseUrl}/transit_times/fixed`;

    return HTTP.make(path, 'get').data({ period });
  }

  transitTime({
    period,
    tradeLanes,
    voyages,
    loadingPorts,
    dischargePorts,
    page,
    perPage,
  }) {
    const path = `${this.baseUrl}/transit_times`;
    const query = removeNullProperties({
      trade: tradeLanes.map((trade) => trade.id),
      period: period.id,
      cp_start: period.cp_start,
      cp_end: period.cp_end,
      voyage: voyages.map((voyage) => voyage.id),
      loading_port: loadingPorts.map((port) => port.id),
      discharge_port: dischargePorts.map((port) => port.id),
      per_page: perPage,
      page,
    });

    return HTTP.make(path, 'get').data(query);
  }

  emissions() {
    const path = `${this.baseUrl}/emissions`;

    return {
      get: ({ period, tradeLanes, voyages, commodities, page, perPage }) => {
        const query = removeNullProperties({
          trade: tradeLanes.map((trade) => trade.id),
          period: period.id,
          voyage: voyages.map((voyage) => voyage.id),
          commodity: commodities.map((commodity) => commodity.id),
          per_page: perPage,
          page,
        });

        return HTTP.make(path, 'get').data(query);
      },
      download: ({ voyage_id }) => {
        const query = {
          voyage_id,
        };

        return HTTP.make(`${path}/download`, 'get').data(query);
      },
    };
  }

  claims({
    period,
    tradeLanes,
    voyages,
    loadingPorts,
    dischargePorts,
    commodities,
    page,
    perPage,
  }) {
    const path = `${this.baseUrl}/claims`;
    const query = removeNullProperties({
      trade: tradeLanes.map((trade) => trade.id),
      period: period.id,
      cp_start: period.cp_start,
      cp_end: period.cp_end,
      voyage: voyages?.map((voyage) => voyage.id) || [],
      commodity: commodities.map((commodity) => commodity.id) || [],
      loading_port: loadingPorts?.map((port) => port.id) || [],
      discharge_port: dischargePorts?.map((port) => port.id) || [],
      per_page: perPage,
      page,
    });

    return HTTP.make(path, 'get').data(query);
  }

  bunkers({ type, period, bunkersPorts }) {
    const path = `${this.baseUrl}/bunkers`;
    const query = removeNullProperties({
      period: period.id,
      cp_start: period.cp_start,
      cp_end: period.cp_end,
      type,
      port: bunkersPorts.map((port) => port.id),
    });

    return HTTP.make(path, 'get').data(query);
  }

  bunkersFilters() {
    const path = `${this.baseUrl}/bunkers/filters`;
    return HTTP.make(path, 'get');
  }

  productivityWidgets() {
    const path = `${this.baseUrl}/productivity/widgets`;
    return HTTP.make(path, 'get');
  }

  laycanFilters() {
    const path = `${this.baseUrl}/shipment_laycan/filters`;
    return HTTP.make(path, 'get');
  }

  productivityFilters() {
    const path = `${this.baseUrl}/productivity/filters`;
    return HTTP.make(path, 'get');
  }

  productivity({ reasonForCall, period, productivityPorts }) {
    const path = `${this.baseUrl}/productivity`;
    const query = removeNullProperties({
      period: period.id,
      cp_start: period.cp_start,
      cp_end: period.cp_end,
      reason_for_call: reasonForCall?.id,
      port: productivityPorts?.map((port) => port.id) || [],
    });

    return HTTP.make(path, 'get').data(query);
  }

  invoiceFilters(type) {
    const path = `${this.baseUrl}/invoices/${type}/filters`;
    return HTTP.make(path, 'get');
  }

  invoices(
    {
      tradeLanes,
      statuses,
      loadingPorts,
      companies,
      voyages,
      period,
      page,
      perPage,
    },
    type,
  ) {
    const path = `${this.baseUrl}/invoices/${type}`;

    const query = removeNullProperties({
      trade: tradeLanes.map((trade) => trade.id),
      company: companies?.map((company) => company.id),
      status: statuses?.map((status) => status.id),
      loading_port: loadingPorts?.map((port) => port.id),
      voyage: voyages?.map((voyage) => voyage.id),
      period: period.id,
      cp_start: period.cp_start,
      cp_end: period.cp_end,
      per_page: perPage,
      page,
    });

    return HTTP.make(path, 'get').data(query);
  }

  laytime() {
    const path = `${this.baseUrl}/laytime`;

    return {
      get: ({ period, tradeLanes, laytimeStatuses }) => {
        return HTTP.make(path, 'get').data(
          removeNullProperties({
            period: period.id,
            cp_start: period.cp_start,
            cp_end: period.cp_end,
            tradelanes: tradeLanes?.map((trade) => trade.id),
            statuses: laytimeStatuses?.map((status) => status.id),
          }),
        );
      },
      show: (id) => HTTP.make(`${path}/${id}`, 'get'),
      filters: () => HTTP.make(`${path}/filters`, 'get'),
      table: ({ period, tradeLanes, laytimeStatuses, perPage, page }, search) =>
        HTTP.make(`${path}/table`, 'get').data(
          removeNullProperties({
            period: period.id,
            cp_start: period.cp_start,
            cp_end: period.cp_end,
            tradelanes: tradeLanes?.map((trade) => trade.id),
            statuses: laytimeStatuses?.map((status) => status.id),
            per_page: perPage,
            page,
            search,
          }),
        ),
    };
  }

  waitingForBerth() {
    const path = `${this.baseUrl}/waiting_for_berth`;

    return {
      get: ({
        period,
        tradeLanes,
        reasonForCall,
        waitingForBerthPorts,
        page,
        perPage,
      }) => {
        return HTTP.make(path, 'get').data(
          removeNullProperties({
            period: period.id,
            cp_start: period.cp_start,
            cp_end: period.cp_end,
            reason_for_call: reasonForCall?.id,
            ports: waitingForBerthPorts?.map((port) => port.id) || [],
            tradelanes: tradeLanes?.map((trade) => trade.id),
            per_page: perPage,
            page,
          }),
        );
      },
      filters: () => HTTP.make(`${path}/filters`, 'get'),
    };
  }
}
