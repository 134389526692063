export const PINIA_STORE = {
  ANALYTICS_STORE: 'ANALYTICS_STORE',
  AUTH_STORE: 'AUTH_STORE',
  DEV_STORE: 'DEV_STORE',
  HOME_STORE: 'HOME_STORE',
  KPI_STORE: 'KPI_STORE',
  MAINTENANCE_STORE: 'MAINTENANCE_STORE',
  ORGANISATION_STORE: 'ORGANISATION_STORE',
  PROFILE_STORE: 'PROFILE_STORE',
  SIDE_PANE_STORE: 'SIDE_PANE_STORE',
  STATISTICS_STORE: 'STATISTICS_STORE',
  UNREAD_NOTIFICATIONS_STORE: 'UNREAD_NOTIFICATIONS_STORE',
  CARGO_BOOKING_CREATE: 'CARGO_BOOKING_CREATE',
  CARGO_BOOKING_NOMINATE: 'CARGO_BOOKING_NOMINATE',
} as const;
