type DragScrollElement = HTMLElement & {
  _dragScroll?: {
    onMouseDown: (e: MouseEvent) => void;
    onMouseMove: (e: MouseEvent) => void;
    stopDragging: () => void;
  };
};

export const makeDragScrollable = (el: DragScrollElement): void => {
  let isDragging = false;
  let startX: number;
  let scrollLeft: number;

  const onMouseDown = (e: MouseEvent): void => {
    isDragging = true;
    startX = e.pageX - el.offsetLeft;
    scrollLeft = el.scrollLeft;
    el.style.cursor = 'grabbing';
  };

  const onMouseMove = (e: MouseEvent): void => {
    if (!isDragging) return;

    e.preventDefault();
    const currentX = e.pageX - el.offsetLeft;
    const walk = currentX - startX;
    el.scrollLeft = scrollLeft - walk;
  };

  const stopDragging = (): void => {
    isDragging = false;
    el.style.cursor = '';
  };

  el.addEventListener('mousedown', onMouseDown);
  el.addEventListener('mousemove', onMouseMove);
  el.addEventListener('mouseup', stopDragging);
  el.addEventListener('mouseleave', stopDragging);

  el._dragScroll = {
    onMouseDown,
    onMouseMove,
    stopDragging,
  };
};

export const removeDragScrollable = (el: DragScrollElement): void => {
  if (!el._dragScroll) return;

  el.removeEventListener('mousedown', el._dragScroll.onMouseDown);
  el.removeEventListener('mousemove', el._dragScroll.onMouseMove);
  el.removeEventListener('mouseup', el._dragScroll.stopDragging);
  el.removeEventListener('mouseleave', el._dragScroll.stopDragging);

  delete el._dragScroll;
};

export const dragScroll = {
  beforeMount: (el: DragScrollElement): void => {
    makeDragScrollable(el);
  },
  unmounted: (el: DragScrollElement): void => {
    removeDragScrollable(el);
  },
};

export default dragScroll;
