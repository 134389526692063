import { HTTP } from './HTTP';

export class PublicUserService {
  checkEmail(email: string) {
    const path = '/api/registration/check-email';
    const query = { email };

    return HTTP.make(path, 'get').data(query);
  }

  tradelanes(search: string) {
    const path = '/api/registration/tradelanes';

    return HTTP.make(path, 'get').data({ search });
  }
}
