import { HTTP } from './HTTP.js';

export class OrganisationRolesService {
  constructor(organisationId) {
    this.organisationId = organisationId;
    this.baseUrl = `/api/customer/organisations/${organisationId}/roles`;
  }

  index() {
    return HTTP.make(this.baseUrl, 'get');
  }
}
