import { ref } from 'vue';

import { LANE_TO_CONTINENT, LANE_TO_DESCRIPTION } from '~/constants';

import {
  getCategoricalColors,
  getCategoricalTagColors,
} from '~/features/useColors';

import type { IStatisticsTradeLane, ITradeLane, ITradeLanePort } from '~/types';

export const getUniqueArray = (items: unknown[]) => [...new Set(items)];

const specialCases = Object.keys(LANE_TO_DESCRIPTION);

export const getLaneDescription = (lane: string) => {
  if (specialCases.includes(lane)) {
    return LANE_TO_DESCRIPTION[lane];
  }

  const [startCode, endCode] = lane.replace('/', '-').split('-');

  const start = LANE_TO_CONTINENT[startCode.trim()];
  const end = LANE_TO_CONTINENT[endCode.trim()];

  return `${start} - ${end}`;
};

export const getSearchableLane = (
  tradeLane: string | ITradeLane,
): IStatisticsTradeLane => {
  const typeOfString = typeof tradeLane === 'string';

  const lane = typeOfString ? tradeLane : tradeLane.name;
  const id = typeOfString ? lane : tradeLane.id;

  const continents = getLaneDescription(lane);
  const search = `${lane} ${continents}`;

  return {
    id: id,
    name: lane,
    continents,
    search,
  };
};

const toPort = <T extends ITradeLanePort>(port: T, i: number, all: T[]) => ({
  ...port,
  first: i === 0,
  last: all.length - 1 === i,
});

export const getSailingSchedulePreviewPorts = <T extends ITradeLanePort>(
  portList: T[],
  fullView: boolean,
) =>
  fullView
    ? portList.map(toPort)
    : portList.filter((port) => port.visible).map(toPort);

export const getTradeDeskEmail = (email: string | null) =>
  email || 'Email not added yet';

export const getCallingAtPorts = (callingAtPorts?: string[]) =>
  callingAtPorts?.join(' - ') ?? '';

export const useSailingScheduleTradeColoring = () => {
  const tradeByColor = ref({});

  let colorIndex = 0;

  const getTradeColor = (trade: string) => {
    if (tradeByColor.value[trade]) {
      return tradeByColor.value[trade];
    }

    tradeByColor.value[trade] = {
      color: getCategoricalColors(colorIndex),
      tagVariant: getCategoricalTagColors(colorIndex),
    };

    colorIndex += 1;

    return tradeByColor.value[trade];
  };

  return { getTradeColor };
};
