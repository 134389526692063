import { getUniqueRegistrationSearchTradeLanes } from '~/features/useRegistrationSearch';

import { HTTP } from './HTTP.js';

export class AuthService {
  azureCallback(query) {
    const path = '/api/azure/login';
    return HTTP.make(path, 'get').data(query);
  }

  password() {
    const path = '/api/reset-password';
    return {
      resetRequest: (form) => HTTP.make(`${path}/request`, 'post').data(form),
      store: ({ token, password }) =>
        HTTP.make(`${path}/${token}`, 'post').data({
          password,
          _method: 'put',
        }),
    };
  }

  invite() {
    return {
      store: (form) => HTTP.make('/api/admin/invitations', 'post').data(form),
    };
  }

  user() {
    return {
      store: (form) =>
        HTTP.make(`/api/invitations/${form.token}/accept`, 'post').data(form),
    };
  }

  login({ mail, password, rememberMe }) {
    const path = '/api/login';
    return HTTP.make(path, 'post').data({
      email: mail,
      password,
      remember_me: rememberMe,
    });
  }

  registration() {
    const path = '/api/registration/register';

    return {
      register: ({
        email,
        firstName,
        lastName,
        organisationName,
        password,
        businessType,
        tradelanes,
      }) =>
        HTTP.make(path, 'post').data({
          email,
          password,
          tradelanes: getUniqueRegistrationSearchTradeLanes(tradelanes).map(
            (tradelane) => String(tradelane.id),
          ),
          first_name: firstName,
          last_name: lastName,
          organisation: organisationName,
          business_type: businessType,
        }),
      confirmEmail: (token) => {
        const path = `/api/confirm-email/${token}`;

        return HTTP.make(path, 'post');
      },
    };
  }

  logout() {
    const path = '/api/logout';
    return HTTP.make(path, 'post');
  }
}
