export const VOYAGE_TRACKER_STEP_STATUS = {
  UPCOMING: 'upcoming',
  ACTIVE: 'active',
  ACTIVE_IN_PROGRESS: 'activeInProgress',
  COMPLETED: 'completed',
  DEFAULT: 'default',
} as const;

export const MILESTONE_STATUS_TO_VOYAGE_TRACKER_STEP_STATUS = {
  est_arrival: VOYAGE_TRACKER_STEP_STATUS.UPCOMING,
  arrived: VOYAGE_TRACKER_STEP_STATUS.ACTIVE,
  departed: VOYAGE_TRACKER_STEP_STATUS.COMPLETED,
} as const;
