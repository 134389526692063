export const GA_STATISTICS_PAGES = {
  STATISTICS_SHIPMENT_VOLUMES: {
    name: 'Statistics: Shipment Volumes',
    path: '/statistics/shipment-volumes',
  },
  STATISTICS_SHIPMENT_VOLUMES_KPIS: {
    name: 'Statistics: Shipment Volumes',
    path: '/statistics/shipment-volumes/kpis',
  },
  STATISTICS_LAYCAN_PERFORMANCE: {
    name: 'Statistics: Laycan',
    path: '/statistics/laycan',
  },
  STATISTICS_TRANSIT_TIME: {
    name: 'Statistics: Transit Time',
    path: '/statistics/transit-time',
  },
  STATISTICS_EMISSIONS: {
    name: 'Statistics: Emissions',
    path: '/statistics/emissions',
  },
  STATISTICS_CLAIMS: {
    name: 'Statistics: Claims',
    path: '/statistics/claims',
  },
  STATISTICS_BUNKERS: {
    name: 'Statistics: Bunkers',
    path: '/statistics/bunkers',
  },
  STATISTICS_PRODUCTIVITY: {
    name: 'Statistics: Productivity',
    path: '/statistics/productivity',
  },
  STATISTICS_INVOICES: {
    name: 'Statistics: Invoices',
    path: '/statistics/invoices',
  },
  STATISTICS_LAYTIME: {
    name: 'Statistics: Laytime',
    path: '/statistics/laytime',
  },
  STATISTICS_WAITING_FOR_BERTH: {
    name: 'Statistics: Waiting for Berth',
    path: '/statistics/waiting-for-berth',
  },
} as const;

export const GA_EVENT = {
  NEW_ORGANISATION: {
    action: 'New Organisation',
    params: {
      event_category: 'Organisation',
      event_label: 'New Organisation',
    },
  },
  INVITE_USER_FROM_HOME_PAGE: {
    action: 'Invite User',
    params: {
      event_category: 'User',
      event_label: 'Invite User from Home Page',
    },
  },
  INVITE_USER_FROM_ORGANISATION_PAGE: {
    action: 'Invite User',
    params: {
      event_category: 'User',
      event_label: 'Invite User from Organisation Page',
    },
  },
  SAILING_SCHEDULE_CUSTOMISED: {
    action: 'Customised Sailing Schedule',
    params: {
      event_category: 'Sailing Schedule',
      event_label: 'Sailing Schedule: Customised',
    },
  },
  CARBON_OFFSETTING_MANAGE_OFFSET: {
    action: 'Manage Offset',
    params: {
      event_category: 'Carbon Offsetting',
      event_label: 'Carbon Offsetting: Manage Offset',
    },
  },
  ORGANISATION_CREATE_CUSTOMERS_ADDED: {
    action: 'Added Customer Users',
    params: {
      event_category: 'Organisation',
      event_label: 'Create Organisation: Added Customers',
      value: 0,
    },
  },
  ORGANISATION_CREATE_G2O_ADDED: {
    action: 'Added G2O Users',
    params: {
      event_category: 'Organisation',
      event_label: 'Create Organisation: Added G2O Users',
      value: 0,
    },
  },
} as const;

export const GA_EVENT_ACTIONS = {
  PAGE_VIEW: 'page_view',
  DATE_FILTER: 'Date Filter',
  SHIPMENT_STATUS: 'Shipment Status',
  SHIPMENT_SHARED: 'Shipment Shared',
  INVITE_USER: 'Invite User',
  SAILING_SCHEDULE_CUSTOMIZED: 'Sailing Schedule Customized',
  MANAGE_OFFSET: 'Manage Offset',
  CREATE_ORGANISATION_CUSTOMER_USERS: 'Customer Users Added',
  CREATE_ORGANISATION_G2O_USERS: 'G2O Users Added',
} as const;

export const ANALYTIC_SCRIPT_ID = {
  GOOGLE_TAG_MANAGER: 'ga-tag-manager-script',
  GOOGLE_SCRIPT: 'ga-script',
  HOT_JAR: 'hot-jar-script',
} as const;

export const SLEEK_PLAN_SCRIPT_ID = 'sleek-plan-script';

export const COOKIE_LAW_STATE = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
} as const;
