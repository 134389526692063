import qs from 'qs';

export function hrefToUrl(href: string): URL {
  return new URL(href, import.meta.env.VITE_API_ENDPOINT || 'http://localhost');
}

export function mergeDataIntoQueryString(
  method: string,
  url: URL,
  data: Record<string, unknown>,
): [URL, Record<string, unknown>] {
  if (method === 'get' && Object.keys(data).length) {
    url.search = qs.stringify(data, {
      encodeValuesOnly: true,
      arrayFormat: 'brackets',
    });
    data = {};
  }
  return [url, data];
}

export function urlWithoutHash(url: URL): URL {
  url = new URL(url.href);
  url.hash = '';
  return url;
}
