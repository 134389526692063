export const NOTIFICATION_SIZE = {
  DEFAULT: 'default',
  LARGE: 'lg',
} as const;

export const NOTIFICATION_VARIANT = {
  DEFAULT_READ: 'DEFAULT_READ',
  DEFAULT_UNREAD: 'DEFAULT_UNREAD',
  LARGE_READ: 'LARGE_READ',
  LARGE_UNREAD: 'LARGE_UNREAD',
} as const;

export const MAINTENANCE_AREA = {
  LOGIN: 'LOGIN',
  HOME: 'HOME',
  ALL_BOOKINGS: 'ALL_BOOKINGS',
  CHART_CLAIMS: 'CHART_CLAIMS',
  CHART_EMISSIONS: 'CHART_EMISSIONS',
  CHART_INVOICES: 'CHART_INVOICES',
  CHART_PRODUCTIVITY: 'CHART_PRODUCTIVITY',
  CHART_BUNKERS: 'CHART_BUNKERS',
  CHART_LAYTIMES: 'CHART_LAYTIMES',
} as const;
