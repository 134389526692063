import { LOCAL_STORAGE_KEYS } from '~/constants';

import { getSearchableLane } from '~/features/useSailingSchedule';
import type { SailingScheduleDateType } from '~/features/useSailingScheduleFilters';

import type {
  IAdminUserListPendingUser,
  ILocalStorageKey,
  NominationTradelaneFilterSelections,
} from '~/types';

const preserveKeys = [
  LOCAL_STORAGE_KEYS.GOOGLE_ENABLED,
  LOCAL_STORAGE_KEYS.USER_LIST_QUERY,
  LOCAL_STORAGE_KEYS.ORGANISATION_USER_LIST_QUERY,
  LOCAL_STORAGE_KEYS.SAILING_SCHEDULE_QUERY,
  LOCAL_STORAGE_KEYS.PUBLIC_SAILING_SCHEDULE_QUERY,
] as const;

export const getObject = (key: ILocalStorageKey) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

export const setObject = (key: ILocalStorageKey, value) => {
  if (value) localStorage.setItem(key, JSON.stringify(value));
  else localStorage.removeItem(key);
};

const getNativeType = (key: ILocalStorageKey) => localStorage.getItem(key);

const getBoolean = (key: ILocalStorageKey) => {
  const value = getNativeType(key);
  if (value === null) return false;

  return value.toString() === 'true';
};

const getProfile = () => getObject(LOCAL_STORAGE_KEYS.PROFILE);
const setProfile = (profile) => {
  setObject(LOCAL_STORAGE_KEYS.PROFILE, profile);
};

const getAccessToken = () => getNativeType(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

const getGoogleIsEnabled = () => getBoolean(LOCAL_STORAGE_KEYS.GOOGLE_ENABLED);

const getUserListQuery = () => getObject(LOCAL_STORAGE_KEYS.USER_LIST_QUERY);
const setUserListQuery = (query) => {
  setObject(LOCAL_STORAGE_KEYS.USER_LIST_QUERY, query);
};

const getPendingUserRequests = () =>
  getObject(LOCAL_STORAGE_KEYS.PENDING_USER_REQUESTS);
const setPendingUserRequests = (requests: IAdminUserListPendingUser[]) => {
  setObject(LOCAL_STORAGE_KEYS.PENDING_USER_REQUESTS, requests);
};

const getOrganisationUserListQuery = () =>
  getObject(LOCAL_STORAGE_KEYS.ORGANISATION_USER_LIST_QUERY);
const setOrganisationUserListQuery = (query) => {
  setObject(LOCAL_STORAGE_KEYS.ORGANISATION_USER_LIST_QUERY, query);
};

const getSailingScheduleQuery = () =>
  getObject(LOCAL_STORAGE_KEYS.SAILING_SCHEDULE_QUERY);

const setSailingScheduleQuery = (data) => {
  const query = {
    ...data,
    tradelanes: data.tradelanes.map(getSearchableLane),
  };

  setObject(LOCAL_STORAGE_KEYS.SAILING_SCHEDULE_QUERY, query);
};

const getPublicSailingScheduleQuery = () =>
  getObject(LOCAL_STORAGE_KEYS.PUBLIC_SAILING_SCHEDULE_QUERY);

const setPublicSailingScheduleQuery = (data) => {
  const query = {
    ...data,
    tradelanes: data.tradelanes.map(getSearchableLane),
  };

  setObject(LOCAL_STORAGE_KEYS.PUBLIC_SAILING_SCHEDULE_QUERY, query);
};

const getNominationTradelaneFilter =
  (): NominationTradelaneFilterSelections | null =>
    getObject(LOCAL_STORAGE_KEYS.NOMINATION_TRADELANE_FILTERS);

const setNominationTradelaneFilter = (
  tradelanes: NominationTradelaneFilterSelections,
) => {
  setObject(LOCAL_STORAGE_KEYS.NOMINATION_TRADELANE_FILTERS, tradelanes);
};

const getSailingScheduleDateType = (): SailingScheduleDateType | null =>
  getObject(LOCAL_STORAGE_KEYS.SAILING_SCHEDULE_TIME_ESTIMATE);

const setSailingScheduleDateType = (data: SailingScheduleDateType | null) => {
  setObject(LOCAL_STORAGE_KEYS.SAILING_SCHEDULE_TIME_ESTIMATE, data);
};

const clear = (keepDefaults = true) => {
  Object.values(LOCAL_STORAGE_KEYS).forEach((key) => {
    if (preserveKeys.includes(key) && keepDefaults) {
      return;
    }

    localStorage.removeItem(key);
  });
};

export const storage = {
  getProfile,
  setProfile,
  getAccessToken,
  getGoogleIsEnabled,
  getUserListQuery,
  setUserListQuery,
  getOrganisationUserListQuery,
  setOrganisationUserListQuery,
  getSailingScheduleQuery,
  setSailingScheduleQuery,
  getNominationTradelaneFilter,
  setNominationTradelaneFilter,
  getPublicSailingScheduleQuery,
  setPublicSailingScheduleQuery,
  getSailingScheduleDateType,
  setSailingScheduleDateType,
  getPendingUserRequests,
  setPendingUserRequests,
  clear,
};
