import { email, max, min, required } from '@vee-validate/rules';
import { defineRule } from 'vee-validate';

import { emailIsG2Ocean } from '~/features/useAzureLogin';

import Service from '~/services/Service.js';

import type { IKpiFormPeriod, UserModule } from '~/types';

const getLabel = (field: string, params?: string[]) => {
  const paramLabel = params?.[0];
  // replace underscores and hyphens with spaces
  if (paramLabel) {
    return paramLabel;
  }
  const label = field.replaceAll(/_/g, ' ').replaceAll(/-/g, ' ').trim();
  // capitalize first letter
  return label.charAt(0).toUpperCase() + label.slice(1);
};

const getRequiredLabel = (field: string, params?: string[]) => {
  const paramLabel = params?.[0];
  // replace underscores and hyphens with spaces
  if (paramLabel) {
    return paramLabel;
  }
  const label = field.replaceAll(/_/g, ' ').replaceAll(/-/g, ' ').trim();
  return `${label.charAt(0).toUpperCase() + label.slice(1)} is required`;
};

// Setup Vee Validate
// https://vee-validate.logaretm.com/v4/
export const install: UserModule = () => {
  defineRule('required', (value: unknown, params: string[], ctx) => {
    const { field } = ctx;
    const label = getRequiredLabel(field, params);

    return required(value) ? true : label;
  });

  defineRule('email', (value: string) =>
    email(value) ? true : 'This must be a valid email address',
  );

  defineRule('email_numeric', (value: string) => {
    const [local, domain] = value.split('@');
    const companyPart = domain?.split('.')?.[0] ?? '';
    const isNumeric = (str: string) => /^\d+$/.test(str);
    const hasFullNumericPart = isNumeric(local) || isNumeric(companyPart);

    return hasFullNumericPart
      ? "Email shouldn't contain only numbers before or after @ character"
      : true;
  });

  defineRule('min', (value: string, params: string[], ctx) => {
    const { field, rule } = ctx;
    const label = getLabel(field);
    const [minValue] = rule?.params as string[];
    return min(value, params)
      ? true
      : `${label} must have at least ${minValue} characters`;
  });

  defineRule('max', (value: string, params: string[], ctx) => {
    const { field, rule } = ctx;
    const label = getLabel(field);
    const [maxValue] = rule?.params as string[];
    return max(value, params)
      ? true
      : `${label} should be shorter than ${maxValue} characters`;
  });

  defineRule('confirms', (value: string, [target]: string[]) => {
    return value === target ? true : 'Password confirmation does not match';
  });

  defineRule('g2o_email', (value: string) =>
    emailIsG2Ocean(value) ? true : 'Email domain does not match g2ocean',
  );

  defineRule('not_g2o_email', (value: string) => {
    return emailIsG2Ocean(value)
      ? 'This is a customer user field. Please add G2O user in a previous step.'
      : true;
  });

  defineRule('unique_shared_email', (value: string, params: string[]) => {
    return params.includes(value) && value?.length
      ? 'This email has already been added'
      : true;
  });

  defineRule('unique_email', async (value: string) => {
    return new Promise<boolean | string>((resolve) => {
      Service.users()
        .uniqueEmail(value)
        .onSuccess(() => resolve(true))
        .onError(() => resolve('This email already exists'))
        .execute();
    });
  });

  defineRule('check_email', async (value: string) => {
    return new Promise<boolean | string>((resolve) => {
      Service.publicUser()
        .checkEmail(value)
        .onSuccess(() => resolve(true))
        .onError((error) => {
          const { message } = error.response.data;

          resolve(message);
        })
        .execute();
    });
  });

  defineRule('unique_name', async (value: string, [id]: string[]) => {
    return new Promise<boolean | string>((resolve) => {
      Service.organisationList()
        .uniqueName(value, id)
        .onSuccess(() => resolve(true))
        .onError(() =>
          resolve('Organisation name already exists. Specify another name.'),
        )
        .execute();
    });
  });

  defineRule('g2password', (value: string, _params, ctx) => {
    const { field } = ctx;
    const label = (field.charAt(0).toUpperCase() + field.slice(1)).replaceAll(
      /_/g,
      ' ',
    );

    const message = `${label} must contain at least one uppercase letter and one number`;
    // at least one uppercase letter - g2oPassword
    // at least one lowercase letter - g2oPassword
    // at least one number - g2oPassword
    if (typeof value !== 'string') return message;

    const hasNumber = /\d/.test(value);
    const hasUpperCaseLetter = /[A-Z]/.test(value);
    const hasLowerCaseLetter = /[a-z]/.test(value);

    return hasNumber && hasUpperCaseLetter && hasLowerCaseLetter
      ? true
      : message;
  });

  defineRule('booking_no', (value: string) => {
    // Booking No. format validation
    // Example 21GOT1234 - explanation: 21 = year, GOT = office, 0022 = running number
    // \d{2} - two digits
    // [A-Za-z]{3} - followed by 3 letters
    // \d{4} - followed by 4 digits
    const rule = /^\d{2}[A-Z]{3}\d{4}$/g;

    if (!value) {
      return true;
    }

    const isValidFormat = rule.test(value);

    return isValidFormat
      ? true
      : 'Incorrect format. Please enter ID generated in Dataloy';
  });

  defineRule('invalid_invite', (value: string, [isAdmin]: string[]) => {
    const message =
      isAdmin === 'true'
        ? 'G2O users can only be invited via User List.'
        : 'In order to invite G2O user, contact your Administrator.';

    return !emailIsG2Ocean(value) ? true : message;
  });

  defineRule('kpiRangeContractRequired', (value: IKpiFormPeriod) => {
    const [start, end] = value || [null, null];
    return !!start && !!end ? true : 'Period is required';
  });

  // TODO: Once custom defined required/min/max rule will be refactored
  // to use field specific label, not by removing dashes between field.name words
  // this rule can be removed.
  defineRule('maxDaysValidation', (value: string, params: string[], ctx) => {
    const { rule } = ctx;
    const [minValue] = rule?.params as string[];

    if (!max(value, params))
      return `Max days. should be shorter than ${minValue} characters`;

    return required(value) ? true : 'Max days. is required';
  });

  defineRule('shipmentVolumeRange', (value: string, params: string[], ctx) => {
    const [parentFieldKey, index, key] = params;

    const { keys, compare, message } = SHIPMENT_VOLUME_RANGE[key];

    const field = ctx.form[parentFieldKey][index] as Record<string, string>;
    if (!field) return true;

    if (keys.map((key) => field[key]).every(Boolean)) {
      return compare(field[keys[0]], field[keys[1]]) ? true : message;
    }

    return true;
  });

  defineRule('atLeastOneVolumesRequired', (value) => {
    if (value) {
      return true;
    }

    return 'At least one of Volumes is required';
  });
};

const SHIPMENT_VOLUME_RANGE = {
  period_min_volume: {
    keys: ['period_min_volume', 'period_max_volume'],
    compare: (min: string, max: string) => parseInt(min) <= parseInt(max),
    message: 'Min. Volume / Period should be less than Max',
  },
  period_max_volume: {
    keys: ['period_min_volume', 'period_max_volume'],
    compare: (min: string, max: string) => parseInt(min) <= parseInt(max),
    message: 'Max. Volume / Period should be more than Min',
  },
  shipment_min_volume: {
    keys: ['shipment_min_volume', 'shipment_max_volume'],
    compare: (min: string, max: string) => parseInt(min) <= parseInt(max),
    message: 'Min. Volume / Shipment should be less than Max',
  },
  shipment_max_volume: {
    keys: ['shipment_min_volume', 'shipment_max_volume'],
    compare: (min: string, max: string) => parseInt(min) <= parseInt(max),
    message: 'Max. Volume / Shipment should be more than Min',
  },
};
