import { nextTick } from 'vue';
import { createPopper } from '@popperjs/core';

type TooltipDirectiveEl = HTMLElement & {
  __show__?: () => void;
  __hide__?: () => void;
  __tooltip__?: HTMLDivElement;
};

const title = {
  mounted(el: TooltipDirectiveEl) {
    nextTick(() => {
      const hasOverflow =
        el.offsetWidth < el.scrollWidth || el.offsetHeight < el.scrollHeight;

      if (!hasOverflow || !el.textContent) {
        return;
      }

      const tooltip = document.createElement('div');

      tooltip.id = 'tooltip';
      tooltip.classList.add(
        'z-[100]',
        'w-max',
        'max-w-sm',
        'rounded',
        'bg-charcoal-2',
        'p-4',
        'text-sm',
        'text-charcoal-9',
      );

      tooltip.innerText = el.textContent;
      el.__tooltip__ = tooltip;
      const arrow = document.createElement('div');

      arrow.id = 'arrow';
      arrow.setAttribute('data-popper-arrow', '');

      tooltip.appendChild(arrow);

      const tooltipTitleElement = document.querySelector('#tooltipTitle');

      if (!tooltipTitleElement) {
        return;
      }

      tooltipTitleElement.appendChild(tooltip);

      const popperInstance = createPopper(el, tooltip, {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
          {
            name: 'arrow',
            options: {
              element: arrow,
            },
          },
          {
            name: 'preventOverflow',
            options: {
              boundary: el,
            },
          },
        ],
      });

      el.__show__ = function show() {
        tooltip.setAttribute('data-show', '');
        popperInstance.update();
      };

      el.__hide__ = function hide() {
        tooltip.removeAttribute('data-show');
      };

      el.addEventListener('mouseover', el.__show__);
      el.addEventListener('mouseleave', el.__hide__);
    });
  },

  unmounted(el: TooltipDirectiveEl) {
    if (el.__tooltip__) {
      el.__tooltip__.remove();
    }
    if (el.__show__) {
      el.removeEventListener('mouseover', el.__show__);
    }
    if (el.__hide__) {
      el.removeEventListener('mouseleave', el.__hide__);
    }
  },
};

export default title;
