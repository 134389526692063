export const PERMISSION = {
  SHIPMENT_VIEW_OWN: 'shipment.view.own',

  KPI_CREATE_OWN: 'kpi.create.own',
  KPI_VIEW_OWN: 'kpi.view.own',
  KPI_UPDATE_OWN: 'kpi.update.own',

  DASHBOARD_VIEW_OWN: 'dashboard.view.own',

  ORGANISATION_VIEW_ANY: 'organization.view.any',
  ORGANISATION_CREATE_ANY: 'organization.create.any',
  ORGANISATION_VIEW_OWN: 'organization.view.own',
  ORGANISATION_UPDATE_ANY: 'organization.update.any',
  ORGANISATION_UPDATE_OWN: 'organization.update.own',
  ORGANISATION_USERS_VIEW_OWN: 'organization_users.update.own',

  FEATURE_BOOKINGS_VIEW: 'feature.bookings.view',
  BOOKINGS_VIEW_ANY: 'bookings.view.any',
  BOOKINGS_VIEW_OWN: 'bookings.view.own',

  BOOKINGS_CREATE_ANY: 'bookings.create.any',
  BOOKINGS_CREATE_OWN: 'bookings.create.own',

  BOOKINGS_UPDATE_OWN: 'bookings.update.own',
  BOOKINGS_UPDATE_ANY: 'bookings.update.any',

  USERS_VIEW_ANY: 'user.view.any',

  SAILING_SCHEDULE_VIEW_OWN: 'sailing_schedule.view.own',

  // Public User
  PUBLIC_VESSELS_VIEW_OWN: 'public_vessels.view.own',
} as const;
