export const CATEGORICAL_DAVIZ_COLORS = [
  '#266764',
  '#F3925C',
  '#66BCBC',
  '#C57AFF',
  '#1F8CD8',
  '#A6295F',
  '#254AA0',
  '#B1851C',
  '#51050B',
  '#D1558A',
  '#0F3D65',
  '#46A86D',
];

export const CATEGORICAL_DAVIZ_COLORS_TO_TAG = {
  OCEAN_8: 'contrast-ocean-8',
  ORANGE_3: 'contrast-orange-3',
  OCEAN_4: 'contrast-ocean-4',
  PURPLE_3: 'contrast-purple-3',
  NAVY_BLUE_7: 'contrast-navy-blue-7',
  MAGENTA_8: 'contrast-magenta-8',
  INDIGO_9: 'contrast-indigo-9',
  AMBER: 'contrast-amber-9',
  CHESTNUT_9: 'contrast-chestnut-9',
  PINK_4: 'contrast-pink-4',
  NAVY_BLUE_10: 'contrast-navy-blue-10',
  GREEN_8: 'contrast-green-8',
} as const;
